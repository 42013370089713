<template>
    <div>
        <a
            class="template-name"
            @click.prevent="openTemplate">
            {{ item.display_name }}
        </a>
        <div class="icon-holder">
            <span
                v-for="(channel, i) in availableChannels"
                :key="i"
                class="icon">
                <icon
                    :name="channel"
                    size="15" />
            </span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    computed: {
        availableChannels() {
            const channels = new Set();
            this.item.configuration.pages.forEach(p => {
                p.slots.forEach(s => {
                    const slotChannel = s.channel ?? p.meta.platform ?? '';
                    channels.add(slotChannel);
                });
            });
            const formattedChannels = [];
            channels.forEach(c => {
                if (c !== '') { formattedChannels.push(c.toLowerCase()) }
            });
            return formattedChannels;
        }
    },
    methods: {
        openTemplate() {
            this.$emit('callback');
        }
    }
};
</script>

<style lang="scss" scoped>
.template-name {
    display: inline-block;
    transform: translateY(-5px);
}
.icon-holder {
    transform: translateY(5px);
    display: flex;
    align-items: center;
}
.icon {
    margin-right: 10px;
}
</style>